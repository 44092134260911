
import Vue from 'vue'
import { favicons, images, metadata } from '~/assets/ts/utils/metadata'
import SiteStructure from '~/components/site/Structure.vue'

export default Vue.extend({
  components: { SiteStructure },
  head() {
    return metadata(this, {
      images: images(require('@/assets/images/ogImage.png')),
      description: this.$t('sa.subtitle'),
      favicons: favicons('sa'),
    })
  },
})
